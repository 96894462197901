import React, { useState, useEffect } from 'react'
import { getOperatingSystem, getBrowser } from '../../shared/function';
import {
    Row,
    Col,
    Button
}  from 'react-bootstrap';
import CheckStatus from './checkStatus';

const CheckBrowser = (props) => {

    const [browser, setBrowser] = useState(false);

    useEffect(() =>{
        let browser = getBrowser(window);
        if(browser){
            setBrowser(browser)
        }
    },[])

    return (
        <>
                <CheckStatus
                    title={'Browser Status'}
                    status={browser}
                />
                { browser 
                    ? 
                    <Row>
                        <Col xs={12} className="text-center mt-2  mb-5">
                            <Button 
                                className="btn-lg" 
                                style={{width: '80%'}} 
                                onClick={() =>props.nextSection(2)} 
                                color="info">Go Next&nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i></Button>
                        </Col>
                    </Row>
                    :
                    <Col xs={12} className="text-justify pl-4 mb-4 mt-2" style={{fontSize:20, color:'red'}}>
                        Please use Safari in IOS and Chrome in android only.
                    </Col>
                
                }
            
        </>
    )
}

export default CheckBrowser;