import React, {} from 'react';
import {
    Row, 
    Col,
    Button
}  from 'react-bootstrap';
import SingleImageCompleteView from './SingleImageCompleteView';
import NavView from '../component/NavBar'
import Header from './header';
const ImageCompleteView =  (props) => {

    // const style={
    //     color: 'red',
    //     fontSize: 20,
    //     fontWeight: 600,
    //     position: 'absolute',
    //     top: 10
    // }
    //console.log(props.allImage)
    return (
        <>
            <NavView />
            <Row className="pl-3 pr-3 mt-5 mb-3 text-justify">
                <Header 
                    value={"Thank for taking photos. Go below and view all the photographs you have taken. Retake any photos if you want and submit to continue inspection."}
                />
            </Row>
            <Row className="text-center mt-5 mb-5">
                <Col xs={12} className="text-center">
                    <Button variant="success" style={{width: '80%'}} className="btn-lg" onClick={props.videoStart}><i class="fa fa-check"></i>&nbsp;Submit Images</Button>
                </Col>
            </Row>
            {
                props.allImage.map((a, i) =>{
                    let title = a.name;
                    let aId = a.id;
                    return a.images.map((img, j) =>(
                        <SingleImageCompleteView 
                            image={img}
                            title={(a.images.length>1 ? title+"("+parseInt(j)+")" : title)}
                            retake={() =>props.viewRetake(aId, j)}
                            key={j}

                            />
                    ))
                })
            }
            <Row className="text-center mt-5 mb-5">
                <Col xs={12} className="text-center">
                    <Button variant="success" style={{width: '80%'}} className="btn-lg" onClick={props.videoStart}><i class="fa fa-check"></i>&nbsp;Submit Images</Button>
                </Col>
                
            </Row>
        </>
    );
}
 
export default ImageCompleteView;