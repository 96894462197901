import React, { useState } from 'react';
import {  Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Alert from '../component/Alert/common';
import { testVehicle } from '../shared/function';

const  ModalData = (props) => {

    const [input, setInput] = useState('');
    const [error, setError] = useState(null);

    const submitHandler = (e) =>{
      e.preventDefault();
      if(props.reading.min && props.reading.min > input.length){
        setError(props.reading.errorMsg)
      }
      else{
        console.log(input);
        props.click(input);
      }
    }

    const InputHandler = (text) =>{

      let input = testVehicle(text, props.reading.limit, props.reading.subType)
      setInput(input)
     
    }

    return (
      <div>
          <Modal size="md" isOpen={props.modal} >
            <ModalHeader ><div style={{fontWeight:'bold'}}>{props.reading.title}</div></ModalHeader>
          <ModalBody>
          <div className="card-body" style={{padding: '0px 1.25rem'}}>
            
            {error ? <><Alert classes="alert-danger" msg={error} /><br /></> : null} 
            
            <form method="post" onSubmit={(e) =>submitHandler(e)}>
              <div className="row">
                <div className="col-sm-12 text-center mb-2" style={{color: '#142e4e', fontSize: 18, fontWeight: 'bold'}}>
                    {props.reading.content}
                </div>
                <div className="col-sm-12">
                    <div className="form-group">
                        
                        <input 
                          type={props.reading.type} 
                          required 
                          value={input} 
                          className="form-control" 
                          placeholder={props.reading.title}
                          onChange={(e) => InputHandler(e.target.value)} />
                    </div>
                </div>
                <div className="col-sm-8">
                </div>
                {/* <div className="col-sm-3">
                    <div className="form-group">
                      <Button color="danger" onClick={props.toggle}>Cancel</Button>
                    </div>
                </div> */}
                <div className="col-sm-4">
                    <div className="form-group">
                        <button type="submit" className="btn btn-success waves-effect waves-light"><i class="fa fa-check"></i>&nbsp;Submit</button>
                    </div>
                </div>
              </div>
            </form>
          </div>
          </ModalBody>
        </Modal>
      </div>
    );
}

export default ModalData;
