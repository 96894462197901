import firebase from 'firebase';

const config = {
    apiKey: "AIzaSyADn-Wdx8ALuWNyWdN9YGaxELiUNVmKvt4",
    authDomain: "jadu-182214.firebaseapp.com",
    databaseURL: "https://jadu-182214.firebaseio.com",
    projectId: "jadu-182214",
    storageBucket: "jadu-182214.appspot.com",
    messagingSenderId: "561004435718",
    appId: "1:561004435718:web:7673d223c244d5c18fa687",
    measurementId: "G-P5YHS6SGNY"
}

firebase.initializeApp(config);

export default firebase;