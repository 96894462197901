import React, {} from 'react';
import {
    Row, 
    Col, 
    Button
}  from 'react-bootstrap';
const ImageView =  (props) => {

    const style={
        background: 'rgb(255 255 255 / 0.6)',
        color: 'red',
        fontSize: 20,
        fontWeight: 600,
        position: 'absolute',
        top: 35
    }

    // const style1={
    //     position: 'absolute',
    //     top: 100
    // }

    return (
        <>
            <Row style={{ position: 'relative'}}>
                <Col md={12}>
                    <img alt={"text"} style={{width: '100%', height: '380px'}} src={props.image} />
                </Col>
            </Row>
            <Row className="text-center" >
                <Col xs={12} style={style} >
                    {props.title}. 
                </Col>
            </Row>
            
            <Row className="text-right mr-1" style={{marginTop: '-35%'}} >
                <Col xs={12} className="pr-4 mb-4 text-right" >
                    <Button variant="danger" className="btn-lg" onClick={props.retake}><i class="fa fa-repeat" aria-hidden="true"></i>&nbsp;Retake Image</Button>
                </Col>
                <Col xs={12} className="pr-4 text-right">
                    <Button variant="success" className="btn-lg" onClick={props.submit} ><i class="fa fa-check"></i>&nbsp;{props.submitTitle} Image</Button>
                </Col>
            </Row>
        </>
    );
}
 
export default ImageView;