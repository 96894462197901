import React from 'react';
import {
    Row,
    Col,
    Button
}  from 'react-bootstrap';
const checkStatus = (props) =>{
    const stylePassed = {
        fontWeight:900,
        color:'green',
    }

    const dotPassed  ={
        height: 15,
        width: 15,
        backgroundColor: 'green',
        borderRadius: '50%',
        display: 'inline-block',
        marginRight: 5,
    }

    const styleFailed = {
        ...stylePassed,
        color:'red',
    }

    const dotFailed  ={
        ...dotPassed,
        backgroundColor:'red',
    }

    return(
        <Col xs={12} className="text-center">
            {props.title}: {props.status 
            ? 
                <span style={stylePassed} >
                    <span style={dotPassed}></span>Passed
                </span>

            : 
                <span style={styleFailed}>
                    <span style={dotFailed}></span>Failed
                </span>} 
        </Col>
    )
}

export default checkStatus;