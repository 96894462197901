import React from 'react';
import {
    Row,
    Col,
    Button
}  from 'react-bootstrap';
import NavView from '../../component/NavBar';
import SingleInfoView from '../../component/SingleInfoView';

const ChassisNumber = (props) =>{

    // const style = {
    //     textAlign: 'center',
    //     fontSize:20,
    //     fontWeight: 700,
    //     color: 'red',
    //     marginBottom:10,
    // }

    // const style1={
    //     border: '1px solid black',
    //     padding: 5,
    //     width: '100%',
    // }
    // const style2={
        
    //     paddingTop: 15,
    //     width: '100%',
    //     color:'red',
    // }

   
    return (
        <>
        <div >
            <NavView />
            <div style={{width:'100%'}}>
                <h4 className="text-center pt-3 pb-3 mb-3" style={{background:'white', borderRadius:10}}>How to take Signature</h4>
                <Row style={{fontSize: 19, margin:0}}>
                    
                    <Col xs={12} style={{fontWeight:800}}>
                        Instructions
                    </Col>
                    <SingleInfoView
                        icon="fa fa-pencil"
                        title="Please draw your signature on the signature pad on the next screen."
                        isLast={false}
                    />
                    <Col xs={12} className="text-center mt-5 mb-5">
                        <Button style={{width:'80%'}} onClick={props.handleChange} className="btn-lg" color="info">Go and take Sign&nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i></Button>
                    </Col>
                </Row>
            </div>
        </div>
        </>

    )
}

export default ChassisNumber;