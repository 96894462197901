import React, {useState,useEffect} from 'react';
import ModalOrientation from './modalOrientation';

const OrientationImage = (props) => {
    const [modal, setModal] = useState(true)
     useEffect(() => {
        // alert("Please disable screen orientation to rotate screen.")
    },[])

    return(
        <>
        <div>
            {/* <img style={{width: '100%'}} src={'https://wimwisure-customer-app.herokuapp.com/images/phone-tablet_orientation-switch.png'} alt="img" /> */}
        </div>
        <ModalOrientation 
            modal={modal}
            click={() =>setModal(false)}
        />
        </>
    )
}

export default OrientationImage