import React from 'react';
import {
    Navbar,
}  from 'react-bootstrap';
// import logo from '../../public/images/logo.png';
const NavBar = (props) =>{
    return (
        <Navbar bg="secondary" variant="dark">
            <Navbar.Brand href="#home">
                <img style={{width: 80}} src="images/logo.png" alt="logo" />
            </Navbar.Brand>
            <Navbar.Brand style={{fontSize: 17}}>Web App for Self Inspection</Navbar.Brand>
        </Navbar>
    )
}
export default NavBar;