import React, {useState ,useEffect} from 'react';
import {
    Row,
    Col,
    Button
}  from 'react-bootstrap';
import NavView from '../../component/NavBar';
import firebase from './firebase';
import SingleInfoView from '../../component/SingleInfoView';

const ChassisNumber = (props) =>{
    const [img, setImg] = useState(null);
    useEffect(() => {
        showVideo();
    },[])

    const showVideo = () =>{
        let storageRef = firebase.storage().ref();
        let spaceRef = storageRef.child('video/'+props.subType+'.mp4');
        storageRef.child('video/'+props.subType+'.mp4').getDownloadURL().then((url) => {
            console.log(url);
            setImg(url);
        })
    }

    const style1={
        width: '100%',
    }
    return (
        <>
        <div >
            <NavView />
            <div style={{width:'100%'}}>
                <h4 className="text-center pt-3 pb-3 mb-3" style={{background:'white', borderRadius:10}}>How to take Video </h4>
                <Row style={{fontSize: 19, margin:0}}>
                    <Col xs={12}>
                       Please make a 75 second video capturing Chassis Number, Odometer, Number Plate and (360 degree) Vehicle from all sides.
                       
                    </Col>
                    <Col xs={12} className="text-center mt-3 mb-3">
                        <Button className="btn-lg" style={{width:'80%'}} onClick={props.handleChange} color="info">Go and take Video&nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i></Button>
                    </Col>
                    <Col xs={12} style={{fontWeight:800}}>
                        Please view the demo video for more details
                    </Col>
                    <Col xs={12} style={style1}>
                        {
                            img ? 
                            <video src={img} style={style1} controls>
                                Your browser does not support the video tag.
                            </video>
                            : null
                        }
                        <hr />
                    </Col>
                    <Col xs={12} style={{fontWeight:800}}>
                        Instructions
                    </Col>
                    <SingleInfoView
                        icon="fa fa-video-camera"
                        title="Start Video by capturing chassis number of the vehicle."
                        isLast={false}
                    />
                    <SingleInfoView
                        icon="fa fa-book"
                        title="Record odometer reading."
                        isLast={false}
                    />
                    <SingleInfoView
                        icon="fa fa-street-view"
                        title="Take 360 degree video of the vehicle."
                        isLast={false}
                    />
                    <SingleInfoView
                        icon="fa fa-eercast"
                        title="End video at the end of the vehicle front."
                        isLast={false}
                    />
                    <Col xs={12} className="text-center mt-5 mb-5">
                    </Col>
                </Row>
            </div>
        </div>
        </>

    )
}

export default ChassisNumber;