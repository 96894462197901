import React, { useState, useEffect } from 'react'
import {
    Row,
    Col,
    Button
}  from 'react-bootstrap';
import CheckStatus from './checkStatus';

const CheckLocation = (props) => {

    const [location, setLocation] = React.useState(null);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(function(position) {
            console.log("Latitude is :", position.coords.latitude);
            console.log("Longitude is :", position.coords.longitude);
            setLocation({
                lat:position.coords.latitude,
                long:position.coords.longitude
            })
        },
        function(error) {
            if (error.code == error.PERMISSION_DENIED)
              console.log("you denied me :-(");
        }
        );
        
        // window.addEventListener('orientationchange', setScreenOrientation);
    },[])

    return (
        <>
            <CheckStatus
                title={'Location Status'}
                status={location}
            />
            { location ? 
                <Col xs={12} className="text-center mt-2 mb-5">
                    <Button className="btn-lg" style={{width: '80%'}} 
                        onClick={() => props.handleLocation(location)} 
                        color="info">Start Inspection&nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i></Button>
                </Col>
                :
                <>
                    <Col xs={12} className="text-justify pl-4 mb-2 mt-4" style={{fontSize:20, color:'red'}}>
                        Please enable location to start inspection.
                    </Col>
                    {/* <Col xs={12} className="text-justify pl-4 mb-4 mt-2" style={{fontSize:20, color:'red'}}>
                        To enable location got to setting and  enable location.
                    </Col> */}
                </>
            }
        </>
    )
}

export default CheckLocation;