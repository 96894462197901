import React from 'react';

const error = (props) =>(
    <div className={"alert "+props.classes+" alert-dismissible mb-0 fade show "} role="alert">
        <strong>{props.msg}</strong>
    </div>
)

export default error;

