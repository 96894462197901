import React, {useState, useEffect} from 'react'
// import VideoRecorder from 'react-video-recorder'
import moment from 'moment';
import axios from '../axios-details';
import VideoView from "./videoView";
import Loading from '../component/loader';
import VideoStaticView from '../container/Info/videoInfo';
import VideoRecorder from './VideoRecorder';


const RecordVideo = (props) => {
    const [submit, setSubmit] = useState('Submit');
    const [loadingPercent, setLoadingPercent] = useState(0)
    //const [videoComplete, setVideoComplete] = useState(false);
    const [video, setVideo] = useState(null);
    const [isFirst, setIsFirst] = useState(false)

    const onRecordingComplete = (videoBlob) =>{
        //console.log(videoBlob);
        //alert(videoBlob);
        // const blob = new Blob(videoBlob, {
        //     type: "video/mp4"
        // });
        setVideo(videoBlob)
    }

    useEffect(() => {
        if(props.isFirst){
          setIsFirst(true);
        }
      },[])

    const sendDataToAPI = () =>{
        setLoadingPercent(0);
        setSubmit("Loading...");
        
        const formData = new FormData();

        let videoName = new Date().getTime()+".mp4";
        // let videoName = props.caseId+".mp4";
        let snapTime = moment.unix((new Date().getTime())/1000).format("YYYY/MM/DD HH:mm:ss");

        formData.append('case_id', props.caseId);        
        // formData.append('image_value', singleImage.images[0]);
        formData.append('video', video);
        formData.append('video_name', videoName);
        
        
        formData.append('answer',0);
        formData.append('latitude', props.lat);
        formData.append('longitude', props.long);
        formData.append('snap_time', snapTime);
        
        // for (var value of formData.values()) {
        //     console.log(value); 
        // }
        const config = {     
          headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post('/util/customer-case-video/v2', formData, {
            onUploadProgress: progressEvent => {
              let per = Math.round(progressEvent.loaded / (progressEvent.total*1.2) * 100);
              setLoadingPercent(per);
              //console.log("Uploaded "+per+ "%");
            }},config)
        .then(res => {
            //console.log(res);
            setSubmit("Submit");
            let video_details = [{
                answer:0,
                latitude:props.lat,
                longitude:props.long,
                fileName: videoName
            }]
            props.completeInspection(video_details);
        })
        .catch(err =>{
            console.log(err);
            setSubmit("Submit");
        })
    }

    const retake = () =>{
        setVideo(null);
        setSubmit("Submit");
    }

    const handleChange = () =>{
        setIsFirst(false);
    }

    return(
        <>
            {
                isFirst 
                ?
                    <VideoStaticView
                        handleChange={handleChange}
                        subType={props.subType}  
                    />
                :
                !video 
                ?
                <VideoRecorder 
                    onRecordingComplete={(videoBlob) => onRecordingComplete(videoBlob)} 
                    timeLimit='75000'
                    constraints={{
                        audio: true,
                        video: {
                            frameRate: 4,
                            },
                    }}
                />

                :
                (submit === "Submit")
                ?
                    <VideoView 
                        video={window.URL.createObjectURL(video)}
                        submitTitle={submit}
                        retake={retake}
                        submit={sendDataToAPI}
                    />
                    :
                    <Loading
                    loadingPercent={loadingPercent}
                    />
            }
            
        </>
        
    )
    
}

export default RecordVideo;