import React from 'react';
import {
    Row,
    Col,
    Button
}  from 'react-bootstrap';
import NavView from '../../component/NavBar';
const CompleteInspection = (props) =>{
    return (
        <>
        <div >
            <NavView />
            <div style={{width:'100%'}}>
                <h4 className="text-center pt-3 pb-3 mb-3" style={{background:'white', borderRadius:10}}>Declaration</h4>
                <Row style={{fontSize: 19, margin:0}} className="text-justify">
                    <Col xs={12} className="text-center mt-3 mb-3">
                        <Button onClick={props.handleChange} className="btn-lg" style={{width: '80%'}} color="info"><i class="fa fa-check" aria-hidden="true"></i>&nbsp;Submit Declaration</Button>
                    </Col>
                    <Col className="mb-2" xs={12}>
                        I/We hereby declare, confirm and agree that:-
                    </Col>
                    <Col className="mb-2" xs={12}>
                        (i) The Motor vehicle proposed for insurance after a break in cover has not met with any accident giving rise to any claim by a Third Party for injury or death caused to any person or damages to any property.
                    </Col>
                    <Col className="mb-2" xs={12}>
                        (ii) I have presented the same vehicle for pre-insurance inspection, which I have proposed for insurance. Identification details noted/photographs taken by the inspecting officials are correct.If later on, at anytime it is found that inspected vehicle and damaged/accidental vehicles are different then neither any claim nor any indemnity in respect of either own damage or third party death or injury or property damage loss nor any benefit shall be available to me/us..
                    </Col>
                    <Col className="mb-2" xs={12}>
                        (iii) Vehicle has been visually inspected in my/ our presence. No damage or no fact which is material to acceptance of this proposal has been hidden/ undisclosed/ withheld by me/us. Damages of vehicle as noted/photographs taken by the inspecting officials are correct.
                    </Col>
                    <Col className="mb-2" xs={12}>
                        (iv) I/We also agree that damages mentioned as per inspection photographs/video shall be excluded in the event of any claim being lodged during the policy period. 
                    </Col>
                    <Col xs={12} className="text-center mt-5 mb-5">
                        <Button onClick={props.handleChange} className="btn-lg" style={{width: '80%'}} color="info"><i class="fa fa-check" aria-hidden="true"></i>&nbsp;Complete Inspection</Button>
                    </Col>
                    
                </Row>
            </div>
        </div>
        </>

    )
}
export default CompleteInspection;