import React, {Component} from 'react'
import SignatureCanvas from 'react-signature-canvas'
import {
    Button
}  from 'react-bootstrap';
import SignatureView from '../container/Info/signatureInfo';
const style={
    position: 'absolute',
    textAlign: 'center',
    top: 35,
    width: '100%',
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
}
const style1={
  background: 'white',
    color: 'red',
    fontSize: 20,
    fontWeight: 600,
}

const style2={
  position: 'absolute',
  marginLeft:'80%',
  top: 150,
  MozUserSelect: "none",
  WebkitUserSelect: "none",
  msUserSelect: "none",
}
class Signature extends Component{
    trim = () => {
        this.props.setImage(this.sigPad.getCanvas()
        .toDataURL('image/png'))
    }

    constructor(props){
        super(props)
        this.state = {
            isFirst: false
        }
    }

    componentDidMount(){
        if(this.props.isFirst){
            this.setState({isFirst: true})
        }
        console.log(this.props.isFirst);
    }

    handleChange = () =>{
        this.setState({isFirst: false})
    }

    
    render () {
        console.log(this.state)
        return (
            <>
            {
                this.state.isFirst 
                ?
                    <SignatureView
                        handleChange={this.handleChange}
                    />
                :
                <>
                <SignatureCanvas 
                penColor='black'
                canvasProps={{ className: 'sigCanvas'}} 
                ref={(ref) => { this.sigPad = ref }}
                />

                <div style={style}>
                <div style={style1}>{this.props.title}.</div>
                </div>
                <div style={style2}>
                    <Button variant="success" className="btn-lg" onClick={this.trim}><i class="fa fa-check"></i>&nbsp;Submit</Button>
                </div>
                </>
            }
            </>

        )
    }
    
}

export default Signature;