import React, {} from 'react';
import {
    Row, 
    Col, 
    Button
}  from 'react-bootstrap';
const ImageView =  (props) => {

    // const style={
    //     background: 'white',
    //     color: 'red',
    //     fontSize: 20,
    //     fontWeight: 600,
    //     position: 'absolute',
    //     top: 10
    // }
    // const style1={
    //     position: 'absolute',
    //     top: 100
    // }
    return (
        <>
            {/* {
                props.inspectionComplete ? 
                <Row className="text-right mr-1 pt-2 pb-2" style={{background: '#6c757d'}} >
                    <Col xs={12} className="mb-3" >
                        <Button variant="success" onClick={props.complete}>Complete Inspection</Button>
                    </Col>
                </Row>
                : null
                
            } */}
            <Row style={{ position: 'relative'}}>
                <Col md={12}>
                    <video style={{width: '100%',height: '390px'}} autoPlay controls="controls" src={props.video}>
                    </video>
                </Col>
            </Row>
            {/* <Row className="text-center" >
                <Col xs={12} style={style} >
                    {props.title} 
                </Col>
            </Row> */}
            {
                !props.inspectionComplete ?
                <Row className="text-right mr-1" style={{marginTop: '-35%'}} >
                    <Col xs={12} className="pr-4 mb-4 text-right" >
                        <Button variant="danger" className="btn-lg" onClick={props.retake}><i class="fa fa-repeat" aria-hidden="true"></i>&nbsp;Retake Video</Button>
                    </Col>
                    <Col xs={12} className="pr-4 text-right">
                        <Button variant="success" className="btn-lg" onClick={props.submit}><i class="fa fa-check"></i>&nbsp;{props.submitTitle} Video</Button>
                    </Col>
                </Row>
                : null
            }
            
        </>
    );
}
 
export default ImageView;