import React, {useState ,useEffect} from 'react';
import {
    Row,
    Col,
    Button
}  from 'react-bootstrap';
import NavView from '../../component/NavBar';
import firebase from './firebase';
const ChassisNumber = (props) =>{

    const [img, setImg] = useState(null);
    const style = {
        textAlign: 'center',
        fontSize:20,
        fontWeight: 700,
        color: 'red',
        marginBottom:10,
    }

    const style1={
        border: '1px solid black',
        padding: 5,
        width: '100%',
    }
    const style2={
        
        paddingTop: 15,
        width: '100%',
        color:'red',
        textAlign: 'justify',
        fontSize: 17
    }

    useEffect(() => {
        showImage();
    },[])

    const showImage = () =>{
        let storageRef = firebase.storage().ref();
        let spaceRef = storageRef.child('chassis/'+props.chassis.chassisId+'.jpg');
        storageRef.child('chassis/'+props.chassis.chassisId+'.jpg').getDownloadURL().then((url) => {
            console.log(url);
            setImg(url);
            props.setChassisImage(url)
        })
    }
    return (
        <>
        <div >
            <NavView />
            <div style={{width:'100%'}}>
                <h4 className="text-center pt-3 pb-3 mb-3" style={{background:'white', borderRadius:10}}>Chassis Location</h4>
                <Row style={{fontSize: 19, margin:0}}>
                    <Col xs={12} style={style}>
                       {props.chassis.chassisLocation}
                    </Col>
                    <Col xs={12} className="text-center mt-2 mb-2">
                        <Button onClick={props.handleChange} className="btn-lg" color="info">Yes, I Know the Chassis Location&nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i></Button>
                    </Col>

                    <Col xs={12} style={style2} className="mb-4">
                        <strong>Warning</strong>: Capturing of chassis number photo is compulsory during the inspection process. Without chassis number, your case will not be approved.
                        
                    </Col>
                    <Col xs={12} style={style1} className="text-center mb-5">
                        {
                            img ? 
                            <img style={{width:'100%'}} src={img} alt="dsa"></img>
                            : null
                        }
                        
                    </Col>
                    
                    
                </Row>
            </div>
        </div>
        </>

    )
}

export default ChassisNumber;