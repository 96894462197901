import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';


class modalOrientation extends Component {

  
  render() {
    return (
      <div>
        <Modal size="md" isOpen={this.props.modal} >
          <ModalHeader ><div style={{fontWeight:'bold'}}>Change Orientation</div></ModalHeader>
          <ModalBody>
          <div className="card-body" style={{padding: '0px 1.25rem'}}>
              <div className="row">
                <div className="col-sm-12" style={{color: '#142e4e', fontSize: 18, fontWeight: 'bold'}}>
                    <div className="form-group">
                        Please disable screen orientation to rotate screen.
                    </div>
                </div>
                <div className="col-sm-8">
                </div>
                <div className="col-sm-4">
                    <div className="form-group">
                        {/* <button type="button" onClick={this.props.click} className="btn btn-success waves-effect waves-light btn-lg"><i class="fa fa-check"></i>&nbsp;Got It</button> */}
                    </div>
                </div>
              </div>
          </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default modalOrientation;
