import React, { useState, useEffect } from 'react';
import CameraView from '../component/CameraView';
import ImageView from '../component/ImageView';
// import RecorderView from '../component/RecorderView';
import axios from '../axios-details';
import moment from 'moment';
import DeviceOrientation, { Orientation } from 'react-screen-orientation'
import ModalConfirm from './modalConfirm';
import ModalData from './modalData';
import ImageComplete from '../component/ImageCompleteView';
import Signature from '../component/signaturePad';
import Loading from '../component/loader';
import Error from '../component/error';
import RecordVideo from '../component/RecordVideo';
import OrientationImage from '../component/OrientationImage'
import firebase from 'firebase';



const PhotoView = (props) =>{
    // const [image, setImage] = useState(null)
    const [singleImage, setSingleImage] = useState(null)
    const [allImage, setAllImage] = useState([]);
    const [screenMode, setScreenMode] = useState('portrait');

    const [capture, setCapture] = useState(0);
    const [submit, setSubmit] = useState('Submit');
    const [location, setLocation] = useState(null);
    const [captureImagePosition,setCaptureImagePosition] = useState(0);

    const [modalConfirm, setModalConfirm] = useState(false)
    const [modalTitle, setModalTitle] = useState(null)

    const [modalData, setModalData] = useState(false)

    const [imagePosition, setImagePosition] = useState(-1);
    const [imageComplete, setImageComplete] = useState(0);
    const [error, setError] = useState(null);

    const [loadingPercent, setLoadingPercent] = useState(0)

    const [reading, setReading] = useState(null);


    const handleSubmit = () => {
        if(singleImage.inputType === "TEXT1" && imageComplete === 0){
            handleModalOpen(singleImage.id);
        }
        else{
            console.log("sfhere")
            updateAllImage(singleImage.reading);
            sendDataToAPI(singleImage.reading); 
        }
        
    }

    const handleModalOpen =(id) =>{
        if(id.trim() === 'chachis-number'){
            setReading({
                title:"Enter Chassis Number",
                content:"Please enter last 6 digits of Chassis Number.",
                type:"text",
                limit:6,
                min:6,
                errorMsg:"Enter last 6 digits."
            })
        }
        else if(id.trim() === 'odometer-rpm'){
            setReading({
                title:"Enter Odometer Reading",
                content:"Please do not enter trip reading.",
                type:"text",
                subType:"number",
                limit:100,
                errorMsg:"Enter 100 digits only."
            })
        }
        else{
            setReading({
                title:"Enter Reading",
                content:"Enter Reading",
                type:"text",
                limit:100,
                errorMsg:"Enter 100 digits only."
            })
        }
        setModalData(true);
        
    }

    const updateAllImage = (reading) =>{
        //console.log(singleImage);
        let _allImage = allImage
        _allImage.map((a) =>{
            if(a.id === singleImage.id){
                a.images = singleImage.images;
                a.reading = reading;
                a.fName = singleImage.fName;
                a.fileName = singleImage.fileName;
            }
            return a;
        });
        //console.log(_allImage);
        
        setAllImage([
            ..._allImage
        ])
        //console.log(_allImage);
    }

    const sendDataToAPI = (reading) =>{
        setSubmit("Loading...");
        const formData = new FormData();
        setLoadingPercent(0);
        let snapTime = moment.unix((new Date().getTime())/1000).format("YYYY/MM/DD HH:mm:ss");

        formData.append('case_id', props.caseId);        
        // formData.append('image_value', singleImage.images[0]);
        let imageToSend = singleImage.images[captureImagePosition-1].split(";");
        //console.log(imageToSend[1]);
        let l1  = imageToSend[1].split(',');
        formData.append('image_value', l1[1]);
        formData.append('image_name', singleImage.fName[captureImagePosition-1]);
        formData.append('image_type', 'image/jpg');
        
        formData.append('answer', reading);
        formData.append('latitude', location.lat);
        formData.append('longitude', location.long);
        formData.append('photo_type', singleImage.id);
        formData.append('snap_time', snapTime);
        
        const config = {     
            headers: { 'content-type': 'multipart/form-data' }
        }
        for (var value of formData.values()) {
            //console.log(value); 
        }
        axios.post('/util/customer-case-image/v2', formData, {
            onUploadProgress: progressEvent => {
              let per = Math.round(progressEvent.loaded / (progressEvent.total*1.2) * 100);
              setLoadingPercent(per);
              //console.log("Uploaded "+per+ "%");
            }}, config)
        .then(res => {
            console.log(singleImage);
            setCapture(0);
            setSubmit("Submit");
            if(singleImage.multiple && imageComplete === 0){
                let title;
                console.log(singleImage.name);
                if(singleImage.name === 'RC'){
                    title = 'Do you want to take more RC Photo?';
                }
                else  if(singleImage.name === "Other Photos"){
                    title = 'Do you want to take more Other Photos?';
                }
                else{
                    title = 'Do you want to take more '+singleImage.name+' Photo?';
                }
                setModalTitle(title);
                setModalConfirm(true);
            }
            else
                changeImage();

        })
        .catch(err =>{
            console.log(err);
            setCapture(1);
            setSubmit("Submit");
            
        })
    }
    const changeImage = () =>{
        //console.log(allImage);
        let count = 0;
        allImage.forEach((a,i)=>{
            console.log(i)
            //console.log(a)
            if(a.id.trim() === "video" && count === 0){
                console.log("111111")
                setSingleImage(null);
                setImageComplete(2);
                count=1;
            }
            else if(a.images.length === 0 && count === 0){
                console.log("1qqqqqqqqqq11111")
                count=1;
                //console.log(a);
                setSingleImage(a);
            }
            else if(a.id.trim() === "client-signature" && a.images.length !== 0)
            count=0;
        })
        if(count === 0){
            console.log("111aaaaaaaaaaa111")
            setSingleImage(null);
            setImageComplete(1);
        }
        // console.log('count'+count);
        // console.log(props.vehicle);
    }

    useEffect(() => {
        console.log("chassisurtl",props.chassisURL)
        navigator.geolocation.getCurrentPosition(function(position) {
            // console.log("Latitude is :", position.coords.latitude);
            // console.log("Longitude is :", position.coords.longitude);
            setLocation({
                lat:position.coords.latitude,
                long:position.coords.longitude
            })
        });
        getAllImages();
        // window.addEventListener('orientationchange', setScreenOrientation);
    },[])

    // useEffect(() => {
    //     console.log("asdsadsa");
    // }, [window.addEventListener('orientationchange', setScreenOrientation)])

    const getAllImages = () =>{
        // console.log(props)
        // console.log('/util/get-photo-types?case_id='+props.caseId+'&make='+props.vehicle.make+'&model='+props.vehicle.model+'&fuel_type='+props.vehicle.fuel)
        axios.post('/util/get-photo-types?case_id='+props.caseId+'&make='+props.vehicle.make+'&model='+props.vehicle.model+'&fuel_type='+props.vehicle.fuel)
        .then(res => {
            let { data } = res;
                if(data !== 'InspectionDone'){
                    if(data.length === 0){
                        setError("Invalid input values. Please try again.")
                    }
                    else{
                    // let allData = [{
                    //     id:"number-plate",
                    //     name:"Number plate",
                    //     inputType: "NONE",
                    //     multiple: false,
                    //     images:[],
                    //     fName:[],
                    //     fileName:[],
                    //     type:"image",
                    //     reading:0,
                    // },{
                    //     id:"rc",
                    //     name:"RC",
                    //     inputType: "NONE",
                    //     multiple: true,
                    //     images:[],
                    //     fName:[],
                    //     fileName:[],
                    //     type:"image",
                    //     reading:0,
                    // }];
                    console.log(data);
                    let data1 = [
                        ...data
                    ]
                    //console.log(data1);
                    let allData = [];
                    let videoObj = [];
                    let clientSignatureObj = [];
                    let othersObj=[];

                
                    console.log(data1);

                    data1.forEach((d,i)=>{
                        
                        if(d.id !== 'video' && d.id !== 'client-signature'){
                        console.log("d.id",d.id)
                            allData.push({
                                ...d,
                                images:[],
                                fName:[],
                                fileName:[],
                                type:"image",
                                reading:0,
                            })
                        } else if (d.id === 'video') {
                            videoObj.push({
                                ...d,
                                images:[],
                                fName:[],
                                fileName:[],
                                type:"image",
                                reading:0,
                            })
                        } else if (d.id === 'client-signature') {
                            clientSignatureObj.push({
                                ...d,
                                images:[],
                                fName:[],
                                fileName:[],
                                type:"image",
                                reading:0,
                            })
                        } else if (d.id === 'others') {
                            othersObj.push({
                                ...d,
                                images:[],
                                fName:[],
                                fileName:[],
                                type:"image",
                                reading:0,
                            })
                        }
                    }) 
                    allData = [
                        ...allData, 
                        ...othersObj,   
                        ...videoObj,                    
                        ...clientSignatureObj,
                    ];

                    console.log(allData);
                    data1.sort(function(a, b) {
                        const order1 = photoOrder.find(p => a.id === p.id).photo_order
                        const order2 = photoOrder.find(p => b.id === p.id).photo_order

                        return order1 - order2
                    });

                    getImageUrls(data1)
                    setAllImage(allData);
                    let count = 0;
                    allData.forEach((a,i)=>{
                        if(!a.image && count === 0){

                            if(a.id === 'video'){
                                console.log("22222222")
                                setImageComplete(2)
                            }
                            else{
                                setSingleImage(a);
                            }
                            count=1;
                            //console.log(a);
                            
                        }
                    })
                }
            }
            else{
                setError("Inspection of this case is already complete")
            }
        })
        .catch(err =>{
            console.log(err);
            setError("Inspection of this case is already complete")
        })
    }

    function getImageUrls(data) {
        let data1 = [];
        let storageRef = firebase.storage().ref();
    
        // Map each data item to a Promise that resolves when imageURL is fetched
        let promises = data.map((d) => {
            return new Promise((resolve, reject) => {
                let imageSrc = 'nophoto';
    
                if (d.id.includes('chachis-number')) {
                    storageRef.child('chassis/' + props.vehicle.chassis.chassisId + '.jpg').getDownloadURL().then((url) => {
                        console.log(d.id, url);
                        imageSrc = url;
                        data1.push({
                            ...d,
                            imageURL: imageSrc
                        });
                        resolve(); // Resolve the promise
                    }).catch((error) => {
                        console.error(error);
                        resolve(); // Resolve even if there's an error to continue processing other items
                    });
                } else if (props.vehicle.vehicle_type.includes("2-wheeler") || props.vehicle.vehicle_type.includes("2 Wheeler") ) {
                    storageRef.child('stencils/2-wheeler/bikeoverlay/' + d.id + '.webp').getDownloadURL().then((url) => {
                        console.log(d.id, url);
                        imageSrc = url;
                        data1.push({
                            ...d,
                            imageURL: imageSrc
                        });
                        resolve(); // Resolve the promise
                    }).catch(() => {
                        storageRef.child('stencils/2-wheeler/scootoroverlay/' + d.id + '.webp').getDownloadURL().then((url) => {
                            console.log(d.id, url);
                            imageSrc = url;
                            data1.push({
                                ...d,
                                imageURL: imageSrc
                            });
                            resolve(); // Resolve the promise
                        }).catch(()=>{

                            storageRef.child('stencils/2-wheeler/extras/' + d.id + '.webp').getDownloadURL().then((url) => {
                                console.log(d.id, url);
                                imageSrc = url;
                                data1.push({
                                    ...d,
                                    imageURL: imageSrc
                                });
                                resolve(); // Resolve the promise
                            }).catch(() => {
                                data1.push({
                                    ...d,
                                    imageURL: imageSrc // In case of error, imageSrc will be an empty string
                                });
                                resolve(); // Resolve the promise
                            });
                        })
                       
                    });
                } else if (props.vehicle.vehicle_type.includes("4-wheeler") || props.vehicle.vehicle_type.includes("4 Wheeler") ) { 
                    // Handle cases where vehicle type is not "2-wheeler"
                    storageRef.child('stencils/4-wheeler/hatchbackoverlay/' + d.id + '.webp').getDownloadURL().then((url) => {
                        console.log(d.id, url);
                        imageSrc = url;
                        data1.push({
                            ...d,
                            imageURL: imageSrc
                        });
                        resolve(); // Resolve the promise
                    }).catch(() => {
                        storageRef.child('stencils/4-wheeler/luxuryoverlay/' + d.id + '.webp').getDownloadURL().then((url) => {
                            console.log(d.id, url);
                            imageSrc = url;
                            data1.push({
                                ...d,
                                imageURL: imageSrc
                            });
                            resolve(); // Resolve the promise
                        }).catch(()=>{
                            storageRef.child('stencils/4-wheeler/suvoverlay/' + d.id + '.webp').getDownloadURL().then((url) => {
                                console.log(d.id, url);
                                imageSrc = url;
                                data1.push({
                                    ...d,
                                    imageURL: imageSrc
                                });
                                resolve(); // Resolve the promise
                            }).catch(()=>{

                                storageRef.child('stencils/4-wheeler/extras/' + d.id + '.webp').getDownloadURL().then((url) => {
                                    console.log(d.id, url);
                                    imageSrc = url;
                                    data1.push({
                                        ...d,
                                        imageURL: imageSrc
                                    });
                                    resolve(); // Resolve the promise
                                }).catch(() => {
                                    data1.push({
                                        ...d,
                                        imageURL: imageSrc // In case of error, imageSrc will be an empty string
                                    });
                                    resolve(); // Resolve the promise
                                });
                            })
                           
                        })
                      
                    });            
                } else if (props.vehicle.vehicle_type.includes("commercial") || props.vehicle.vehicle_type.includes("Commercial")) { 
                    // Handle cases where vehicle type is not "2-wheeler"
                    storageRef.child('stencils/commercial/pickupoverlay/' + d.id + '.webp').getDownloadURL().then((url) => {
                        console.log(d.id, url);
                        imageSrc = url;
                        data1.push({
                            ...d,
                            imageURL: imageSrc
                        });
                        resolve(); // Resolve the promise
                    }).catch(() => {
                        storageRef.child('stencils/commercial/truckoverlay/' + d.id + '.webp').getDownloadURL().then((url) => {
                            console.log(d.id, url);
                            imageSrc = url;
                            data1.push({
                                ...d,
                                imageURL: imageSrc
                            });
                            resolve(); // Resolve the promise
                        }).catch(()=>{
                            storageRef.child('stencils/commercial/extras/' + d.id + '.webp').getDownloadURL().then((url) => {
                                console.log(d.id, url);
                                imageSrc = url;
                                data1.push({
                                    ...d,
                                    imageURL: imageSrc
                                });
                                resolve(); // Resolve the promise
                            }).catch(() => {
                                data1.push({
                                    ...d,
                                    imageURL: imageSrc // In case of error, imageSrc will be an empty string
                                });
                                resolve(); // Resolve the promise
                            });
                        })
                        
                    });        
                }
                else {
                      data1.push({
                        ...d,
                        imageURL: imageSrc // In case of non-2-wheeler, imageSrc will be an empty string
                    });
                    resolve(); // Resolve the promise
                }
            });
        });
    
        // Wait for all promises to resolve
        Promise.all(promises).then(() => {
            console.log(data1); // Print allData after all promises have resolved

            let allData = [];
            let videoObj = [];
            let clientSignatureObj = [];
            let othersObj=[];
            let chassisObj=[];


            data1.forEach((d,i)=>{
                
                if(d.id !== 'video' && d.id !== 'client-signature' && d.id!=='others' && d.id!=='chachis-number'){
                console.log("d.id",d.id)
                    allData.push({
                        ...d,
                        images:[],
                        fName:[],
                        fileName:[],
                        type:"image",
                        reading:0,
                    })
                } else if (d.id === 'video') {
                    videoObj.push({
                        ...d,
                        images:[],
                        fName:[],
                        fileName:[],
                        type:"image",
                        reading:0,
                    })
                } else if (d.id === 'client-signature') {
                    clientSignatureObj.push({
                        ...d,
                        images:[],
                        fName:[],
                        fileName:[],
                        type:"image",
                        reading:0,
                    })
                } else if (d.id === 'others') {
                    othersObj.push({
                        ...d,
                        images:[],
                        fName:[],
                        fileName:[],
                        type:"image",
                        reading:0,
                    })
                } else if (d.id === 'chachis-number') {
                    chassisObj.push({
                        ...d,
                        images:[],
                        fName:[],
                        fileName:[],
                        type:"image",
                        reading:0,
                    })
                }
            }) 
            allData = [...chassisObj,
                ...allData, 
                ...othersObj,   
                ...videoObj,                    
                ...clientSignatureObj,
            ];

            console.log(allData);
            setAllImage(allData);
            let count = 0;
            allData.forEach((a,i)=>{
                if(!a.image && count === 0){

                    if(a.id === 'video'){
                        console.log("22222222")
                        setImageComplete(2)
                    }
                    else{
                        setSingleImage(a);
                    }
                    count=1;
                    //console.log(a);
                    
                }
            })
        }).catch((error) => {
            console.error("Error occurred:", error);
        });
    }
    

    const setScreenOrientation = () => {
        if (window.matchMedia("(orientation: portrait)").matches) {
            console.log('orientation: portrait');
            setScreenMode('portrait')
        }
    
        if (window.matchMedia("(orientation: landscape)").matches) {
            console.log('orientation: landscape');
            setScreenMode('landscape')
         
        }
    }

    const handleImageChange = (image) => {
        let imageFileName;
        let imageName;
        if(singleImage.id === "client-signature"){
            imageFileName = new Date().getTime()+".png";
            imageName = singleImage.id+"_"+location.lat+"_"+location.long+"_"+new Date().getTime()+".png";
        }
        else{
            imageFileName = new Date().getTime()+".jpg";
            imageName = singleImage.id+"_"+location.lat+"_"+location.long+"_"+new Date().getTime()+".jpg";
        }
        imageName = imageFileName;
        
        let allImage = singleImage.images;
        let allImg = singleImage.fName;
        let allFileImg = singleImage.fileName;
        //console.log(imagePosition)
        if(imagePosition >= 0){
            allImage[imagePosition] = image;
            allImg[imagePosition] = imageName;
            allFileImg[imagePosition] = imageFileName;
        }
        else{
            allImage.push(image)
            allImg.push(imageName)
            allFileImg.push(imageFileName);
        }
        //console.log(allImage);
        setCaptureImagePosition(allImage.length)
        setSingleImage({
            ...singleImage,
            images: allImage,
            fName: allImg,
            fileName: allFileImg
        })
        setCapture(1);
    }
    const handleRetake = () =>{
        let allImage = singleImage.images;
        let allNames = singleImage.fName;
        let allFileNames = singleImage.fileName;
        //console.log(allImage.length);
        allImage.pop();
        allNames.pop();
        allFileNames.pop();
        setSingleImage({
            ...singleImage,
            images: allImage,
            fName:allNames,
            fileName:allFileNames
        })
        setCapture(0);
    }
    const closeConfirmModal = () =>{
        setModalConfirm(false)
        changeImage();
    }

    const handleConfirmModalClick = () => {
        setModalConfirm(false)
    }

    

    const closeModalData = (data) =>{
        if(data){
            updateAllImage(data);
            sendDataToAPI(data); 
            setSingleImage({
                ...singleImage,
                reading: data
            })
        }
        else{
            updateAllImage(singleImage.reading);
            sendDataToAPI(singleImage.reading); 
        }  
        setModalData(false) 
    }
    //console.log(singleImage);

    const viewRetake = (id, number) =>{
        //console.log(id, number);
        setImagePosition(number)
        allImage.forEach((a,i)=>{
            if(a.id === id){
                setSingleImage(a);
            }
        })
        
    }

    const handleVideoStart = () =>{
        let count =0;
        allImage.forEach((a,i)=>{
            if(a.id.trim() === 'video' && count === 0){
                console.log("333333")
                count = 1;
            }
            else if(a.id.trim() === "client-signature" && a.images.length !== 0)
            count=0;
        })
        if(count === 0){
            convertForDetails();
        }
        
    }  
    
    const convertForDetails = (videoData) =>{
        console.log(allImage);
        let details = [];
        allImage.forEach((a,i)=>{
            let _oneDet = [];
            a.images.forEach((img, j) =>{
                _oneDet.push({
                    answer:a.reading,
                    latitude:location.lat,
                    longitude:location.long,
                    fileName: a.fileName[j]
                })
            })

            // details.push({
            //     id: a.id,
            //     photos: _oneDet
            // })

            if(a.id.trim() === 'video' && videoData ){
                details.push({
                    id:'video',
                    photos:videoData
                })
            }
            else{
                details.push({
                    id: a.id,
                    photos: _oneDet
                })
            }
        })
        let count=0;
        allImage.forEach((a,i)=>{
           

            if(a.id.trim() === 'client-signature' && count === 0 && a.images.length === 0 ){
                console.log("333333")
                setSingleImage(a);
                count = 1;
            }
        })
        if(count === 1){
            
            setImageComplete(0);
        } else props.completeInspection(details)
    }

    return (
        <>
        <DeviceOrientation lockOrientation={'landscape'}>
            <Orientation orientation='landscape' alwaysRender={false}>
                <div>
        {
            !error 
            ?
                
                    singleImage 
                    ? 
                        singleImage.type === "image" 
                        ? 
                            (capture === 1)
                            ? 
                                submit === "Submit" 
                                ?
                                    <ImageView 
                                        image={singleImage.images[captureImagePosition-1]}
                                        retake={() => handleRetake()}
                                        submit={() =>handleSubmit()}
                                        title={singleImage.name}
                                        submitTitle={submit}
                                        /> 
                                    // <Loading />
                                :
                                    <Loading
                                        loadingPercent={loadingPercent}
                                    />
                            :   
                                (singleImage.id === "client-signature")
                                ?
                                <Signature isFirst={true} setImage={(i) => handleImageChange(i)} title={singleImage.name} />
                                :
                                <CameraView vehicle={props.vehicle} chassisURL={props.chassisURL} imageId={singleImage.imageURL} setImage={(i) => handleImageChange(i)} singleAImage={singleImage} title={singleImage.name} />
                        : 
                            null

                    : 
                        imageComplete === 1
                        ?
                            <ImageComplete allImage={allImage} viewRetake={viewRetake} videoStart={handleVideoStart} />
                            : 
                                
                            null
               
                : 
                <Error message={error} />
            }
            {
                modalConfirm ? <ModalConfirm 
                    modal={modalConfirm} 
                    modalTitle={modalTitle}
                    toggle={closeConfirmModal} 
                    click={handleConfirmModalClick} /> : null
            }
            {
                modalData ? <ModalData 
                    modal={modalData} 
                    toggle={() => closeModalData(' ')} 
                    reading={reading}
                    click={closeModalData} /> : null
            }
            </div>
            </Orientation>
            <Orientation orientation='portrait' alwaysRender={false}>
                <OrientationImage />
            </Orientation>
        </DeviceOrientation>
        {
            imageComplete === 2
            ? 
            <RecordVideo 
                lat={location.lat}
                long={location.long}
                caseId={props.caseId}   
                completeInspection={convertForDetails} 
                subType={props.subType}  
                isFirst={true}
            />
            : null
        }
        </>
    )
}

const photoOrder = [
    {
      "id": "chachis-number",
      "photo_order": 1
    },
    {
      "id": "rc",
      "photo_order": 2
    },
    {
      "id": "number-plate",
      "photo_order": 3
    },
    {
      "id": "90-deg-front",
      "photo_order": 4
    },
    {
      "id": "front-side-undercarriage",
      "photo_order": 5
    },
    {
      "id": "45-deg-front-left",
      "photo_order": 6
    },
    {
      "id": "tyre-front-left-dot",
      "photo_order": 7
    },
    {
      "id": "90-deg-left",
      "photo_order": 8
    },
    {
      "id": "90-deg-left1",
      "photo_order": 9
    },
    {
      "id": "tyre-back-left-dot",
      "photo_order": 10
    },
    {
      "id": "45-deg-back-left",
      "photo_order": 11
    },
    {
      "id": "90-deg-back",
      "photo_order": 12
    },
    {
      "id": "back-side-undercarriage",
      "photo_order": 13
    },
    {
      "id": "open-dicky-number-plate",
      "photo_order": 14
    },
    {
      "id": "open-dicky-number-plate-cng",
      "photo_order": 15
    },
    {
      "id": "45-deg-back-right",
      "photo_order": 16
    },
    {
      "id": "tyre-back-right-dot",
      "photo_order": 17
    },
    {
      "id": "90-deg-right",
      "photo_order": 18
    },
    {
      "id": "90-deg-right1",
      "photo_order": 19
    },
    {
      "id": "tyre-front-right-dot",
      "photo_order": 20
    },
    {
      "id": "45-deg-front-right",
      "photo_order": 21
    },
    {
      "id": "ws-outside",
      "photo_order": 22
    },
    {
      "id": "ws-make-model",
      "photo_order": 23
    },
    {
      "id": "engine-compartment",
      "photo_order": 24
    },
    {
      "id": "cng-lpg",
      "photo_order": 25
    },
    {
      "id": "vin-plate-number",
      "photo_order": 26
    },
    {
      "id": "ws-inside",
      "photo_order": 27
    },
    {
      "id": "ws-left-inside",
      "photo_order": 28
    },
    {
      "id": "ws-right-inside",
      "photo_order": 29
    },
    {
      "id": "full-dashboard",
      "photo_order": 30
    },
    {
      "id": "instrument-panel",
      "photo_order": 31
    },
    {
      "id": "odometer-rpm",
      "photo_order": 32
    },
    {
      "id": "air-bags",
      "photo_order": 33
    },
    {
      "id": "sunroof",
      "photo_order": 34
    },
    {
      "id": "stephney-dot",
      "photo_order": 35
    },
    {
      "id": "boom-normal-photo",
      "photo_order": 36
    },
    {
      "id": "boom-raised-photo",
      "photo_order": 37
    },
    {
      "id": "cng-cylinders",
      "photo_order": 38
    },
    {
      "id": "defect",
      "photo_order": 39
    },
    {
      "id": "client-signature",
      "photo_order": 40
    },
    {
      "id": "inspector-photo",
      "photo_order": 41
    },
    {
      "id": "client-agent-photo",
      "photo_order": 42
    },
    {
      "id": "others",
      "photo_order": 44
    },
    {
      "id": "video",
      "photo_order": 45
    },
    {
      "id": "interior-photo",
      "photo_order": 46
    }
  ]

export default PhotoView;