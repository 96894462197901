import React from 'react';
import Alert from './Alert/common';
import Navbar from './NavBar';

const Error = (props) =>{
    return (
        <>
            <Navbar />
            <br />
            <Alert classes="alert-danger" msg={props.message} />
        </>
    )
}

export default Error