import React from 'react';

const Loader = (props) =>{
    return(
        <div className="load">
            <div className="loader"></div>&nbsp;&nbsp;
                <div>File Uploading...({props.loadingPercent}%)</div>
            </div>
    )
}

export default Loader;

// import React from 'react';
// import {
//     Row,
//     Col,
// }  from 'react-bootstrap';
// const Loader = () =>{
//     return(
//         <div className="load">
//             <Row className="text-center">
//                 <Col xs={12}>
//                     <div className="loader"></div>
//                 </Col>
//                 <Col xs={12}>
//                     <br />
//                     <div>File Uploading...</div>
//                 </Col>
//             </Row>
            
//         </div>
//     )
// }

// export default Loader;