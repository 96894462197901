import React from 'react';
import {
    Col
} from 'react-bootstrap';
import Hr from '../Hr';

const SingleInfo = (props) =>{
    return (
       <>
            <Col xs={2} className="text-center">
                <i className={props.icon} ></i>
            </Col>
            <Col xs={10}>
               {props.title}
            </Col>
            {props.isLast ? null : 
            <Col xs={12}>
                <Hr />
            </Col>}
       </>
    )
}

export default SingleInfo;