export const vehicleType = [{"id": "2-wheeler", "name": "2 Wheeler"},{"id": "4-wheeler", "name": "4 Wheeler"}, {"id": "commercial", "name": "Commercial"}];

export const fuelType = [{
                id: "cng",
                name: "CNG"
            },
            {
                id: "cng_petrol",
                name: "CNG + Petrol"
            },
            {
                id: "diesel",
                name: "Diesel"
            },
            {
                id: "electric",
                name: "Electric"
            },
            {
                id: "lpg",
                name: "LPG"
            },
            {
                id: "petrol",
                name: "Petrol"
            }];
