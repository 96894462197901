import React from 'react'
import {
    Col,
}  from 'react-bootstrap';
const Header = (props) =>{
    const style={
        fontSize:20, 
        color:'#142e4e;'
    }
    return(
        <Col xs={12} className={"text-justify pl-4 "+props.classes} style={style}>
            {props.value}
        </Col>
    )
}

export default Header;