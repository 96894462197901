import React, {useEffect} from 'react';
import {
    Row,
    Col,
    Button
}  from 'react-bootstrap';
import NavView from '../../component/NavBar';
import SingleInfoView from '../../component/SingleInfoView';
import Header from '../../component/header';
// import logo from '../../public/images/logo.png';
import axios from '../../axios-details';
import { getOperatingSystem, getBrowser } from '../../shared/function';
import CheckLocation from './CheckLocation';
import CheckBrowser from './CheckBrowser';

const InfoView = (props) =>{
    const [location, setLocation] = React.useState(null);
    const [hold, setHold] = React.useState(false);
    const [checkingState, setCheckingState] = React.useState(1);
    const [vehicle, setVehicle] = React.useState(null)
    
    

    useEffect(() =>{
        fetchCaseInfo();
    }, [props.caseId]);
    
    const fetchCaseInfo = () =>{
        console.log(props.caseId, "sddadsa")
        axios.get('util/get-case-remark?case_id='+props.caseId)
        .then(res => {
            const { data } = res;
            console.log(data);
            setVehicle(data);
            if(data.remark.includes('hold')){
                setHold(true);
            }
        })
        .catch(err =>{
            console.log(err);
        })
    }

    const handleStart = () =>{
        if(hold){
            props.handleHold({make:vehicle.make,
                model:vehicle.model,
                // variant:variant,
                fuel:vehicle.fuel_type,
                vehicle_number: vehicle.vehicle_number,
                vehicle_type:vehicle.vehicle_type,
                make_model_id:vehicle.make_model_id
            });
        }
        else{
            props.handleChange()
        }
    }

    const nextSection = (section) =>{
        setCheckingState(section)
    }

    const handleLocation = (location) =>{
        setLocation(location);
        handleStart();
    }


    // console.log(location)
    return (
        <>
        <div >
            <NavView />
            <div style={{width:'100%'}}>
                <Row style={{fontSize: 19, margin:0}}>
                    <Col xs={12} className="text-center mt-4">
                        <img style={{width: 300}} src="images/logo.png" alt="logo" />
                    </Col>
                    <Header 
                        value={"Welcome to self inspection application. Please read the instructions carefully and proceed for inspection."}
                        classes=" mt-4"
                    />
                    <Col xs={12} className="text-center mt-4">
                        {
                            (checkingState === 1)
                            ?
                            <CheckBrowser
                                nextSection = {nextSection}
                            />
                            : 
                            (checkingState === 2)
                            ?
                            <CheckLocation 
                                handleLocation = {handleStart}     
                            />
                            : null
                    }
                    </Col>
                    
                </Row>
            </div>

            {
                checkingState ==1 
                ?
                <div style={{width:'100%'}}>
                {/* <h4 className="text-center pt-3 pb-3 mb-3" style={{background:'white', borderRadius:10}}>Instructions</h4> */}
                
                    <Row style={{fontSize: 19, margin:0}}>
                        <Col xs={12} className="mb-2" style={{fontWeight:800}}>
                                Instructions
                        </Col>
                        <SingleInfoView
                            icon="fa fa-car"
                            title="Keep Vehicle in Open Location."
                            isLast={false}
                        />
                        <SingleInfoView
                            icon="fa fa-times-circle"
                            title="Do not close the APP during the inspection."
                            isLast={false}
                        />
                        <SingleInfoView
                            icon="fa fa-camera"
                            title="Allow Location & camera permission."
                            isLast={false}
                        />
                        {/* <SingleInfoView
                            icon="fa fa-globe"
                            title="Please use Safari in IOS and Chrome in android only."
                            isLast={true}
                        /> */}
                    </Row>
                </div>
                : 
                <div style={{width:'100%'}}>
                    {/* <h4 className="text-center pt-3 pb-3 mb-3" style={{background:'white', borderRadius:10}}>Instructions</h4> */}
                    
                    <Row style={{fontSize: 19, margin:0}}>
                        <Col xs={12} className="mb-2" style={{fontWeight:800}}>
                                Instructions to enable location
                        </Col>
                        <SingleInfoView
                            icon="fa fa-location-arrow"
                            title="Please enable location at your device."
                            isLast={false}
                        />
                        <SingleInfoView
                            icon="fa fa-first-order"
                            title="Go to location section of browser settings."
                            isLast={false}
                        />
                        <SingleInfoView
                            icon="fa fa-map-marker"
                            title="Allow location for the current URL."
                            isLast={true}
                        />
                    </Row>
                </div>

            }

            
        </div>
        </>

    )
}

export default InfoView;