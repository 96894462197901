import React, { useState, useRef, useEffect } from 'react';
import {
  Row,
  Col,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import './VideoRecorder.css'

const VideoRecorder = ({ onRecordingComplete }) => {
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const videoRef = useRef(null);
  const recordedChunks = useRef([]);

  const [timer, setTimer] = useState(0);
  const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight);

  const initMedia = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: { exact: "environment" }, frameRate: 10 },
        audio: true // Request audio stream
      });
      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
      }

      const recorder = new MediaRecorder(mediaStream);
      setMediaRecorder(recorder);

      recorder.ondataavailable = event => {
        if (event.data.size > 0) {
          recordedChunks.current.push(event.data);
        }
      };

      recorder.onstop = () => {
        const blob = new Blob(recordedChunks.current, { type: 'video/webm' });
        recordedChunks.current = [];
        if (onRecordingComplete) {
          onRecordingComplete(blob);
        }
      };

      return () => {
        mediaStream.getTracks().forEach(track => track.stop());
      };
    } catch (err) {
      console.error('Error accessing media devices.', err);
    }
  };

  useEffect(() => {
    initMedia();
  }, []);

  const startRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.start();
      setRecording(true);
      setTimer(0)

      const timerInterval = setInterval(() => {
        setTimer(prevTimer => {
          if (prevTimer >= 60) {
            clearInterval(timerInterval);
            stopRecording();
          }
          return prevTimer + 1;
        });
      }, 1000);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setRecording(false);
    }
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
  };

  useEffect(() => {
    const handleResize = () => {
      setIsLandscape(window.innerWidth > window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  const videoStyles = isLandscape
    ? { width: '100vw', height: '100vh', objectFit: 'cover' }
    : { width: '100%', height: '390px', objectFit: 'cover' };

  return (
    <Row style={{ position: 'relative' }}>
      <Col md={12}>
        <video ref={videoRef} style={videoStyles} autoPlay muted playsInline />
        {recording ? (
          <div style={{
            position: 'absolute',
            bottom: '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            padding: '10px',
            borderRadius: '5px',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0)', // Fully transparent background
          }}>
            <span style={{ fontSize: '24px', fontFamily: 'monospace', color: 'white' }}>{formatTime(timer)}</span>
          </div>
        ) : (
          <button onClick={startRecording} style={{
            position: 'absolute',
            top: '50%',
            right: '20px',
            transform: 'translateY(-50%)',
            backgroundColor: 'white',
            border: 'none',
            padding: '10px',
            borderRadius: '50%',
            cursor: 'pointer',
            outline: 'none', // Remove outline when focused
          }}>
            <FontAwesomeIcon icon={faCircle} style={{ fontSize: '48px', color: 'red' }} />
          </button>
        )}
      </Col>
    </Row>
  );

  // return (
  //   <div className="video-recorder-container">
  //     <video ref={videoRef} className='video-recorder-stream' autoPlay muted playsInline />
  //     <div className="controls">
  //       {!recorded ? (
  //         recording ? (
  //           <button onClick={stopRecording} className="capture-button">
  //             <i className="fas fa-stop"></i>
  //           </button>
  //         ) : (
  //           <button onClick={startRecording} className="capture-button" disabled={!mediaRecorder}>
  //             <i className="fas fa-circle"></i>
  //           </button>
  //         )
  //       ) : (
  //         <div className="post-recording-controls">
  //           <button onClick={handleSubmit} className="submit-button">
  //             Submit
  //           </button>
  //           <button onClick={handleRetake} className="retake-button">
  //             Retake
  //           </button>
  //         </div>
  //       )}
  //     </div>
  //   </div>
  // );
};

export default VideoRecorder;
