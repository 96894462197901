export const ArrayToObjectArray = (arr) =>{
    const _unique = arr.filter((q, idx) => arr.indexOf(q) === idx);
    let objectArr = [];
    _unique.forEach(u =>{
        objectArr.push({
            id: u,
            name: u
        })
    })

    return objectArr;
}

export const fetchModel = (data, make) =>{
    const model = [];
    data && data.length > 0 && data.forEach(d =>{
        if(d.make.trim() === make.trim()){
            model.push(d.model)
        }
    })
    return model;
}

export const fetchVariant = (data,  make, model) =>{
    const variant = [];
    data && data.length > 0 && data.forEach(d =>{
        if(d.make.trim() === make.trim() && d.model.trim() === model.trim()){
            variant.push(d.variant)
        }
    })
    return variant;
}

export const testVehicle = (text, length, subType) =>{
    text = text.toUpperCase();
    let format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

    if(subType){
        let count = text.length;
        let last = text.substring(count-1, count);
        if(isAlpha(last)){
            return  text.substring(0, count-1);
        }
    }
    if(text.length > length){
        // setAlert("Vehicle number should be at most of 12 characters");
        return text.substring(0, length);
    }
    else if(format.test(text)){
        let count = text.length;
        // setAlert("Vehicle number should not have any special character")
        return text.substring(0,count-1);
    }
    else{
        return text;
    }
}

const isAlpha = (ch) => {
    return typeof ch === "string" && ch.length === 1 && /[A-Za-z]/.test(ch);
}

export const getOperatingSystem = (window) => {
    let operatingSystem = 'Not known';
    if (window.navigator.appVersion.indexOf('Win') !== -1) { operatingSystem = 'Windows OS'; }
    if (window.navigator.appVersion.indexOf('Mac') !== -1) { operatingSystem = 'MacOS'; }
    if (window.navigator.appVersion.indexOf('X11') !== -1) { operatingSystem = 'UNIX OS'; }
    if (window.navigator.appVersion.indexOf('Linux') !== -1) { operatingSystem = 'Linux OS'; }
  
    return operatingSystem;
  }
  
export const getBrowser = (window) => {
    let currentBrowser = 'Not known';
    if (window.navigator.userAgent.indexOf('Chrome') !== -1) { currentBrowser = 'Google Chrome'; }
    else if (window.navigator.userAgent.indexOf('Firefox') !== -1) { currentBrowser = 'Mozilla Firefox'; }
    else if (window.navigator.userAgent.indexOf('MSIE') !== -1) { currentBrowser = 'Internet Exployer'; }
    else if (window.navigator.userAgent.indexOf('Edge') !== -1) { currentBrowser = 'Edge'; }
    else if (window.navigator.userAgent.indexOf('Safari') !== -1) { currentBrowser = 'Safari'; }
    else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'Opera'; }
    else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'YaBrowser'; }
    else { console.log('Others'); }

    if(currentBrowser === 'Safari' || currentBrowser === 'Google Chrome')
        return true;
    else
        return false;
}