import React, {} from 'react';
import {
    Row, 
    Col, 
    Button
}  from 'react-bootstrap';
const SingleImageCompleteView =  (props) => {

    const style={
        background: 'rgb(255 255 255 / 0.6)',
        color: 'red',
        fontSize: 20,
        fontWeight: 600,
        position: 'relative',
        top: 35,
    }
    const style1={
        position: 'relative',
        top: 135,
    }
    console.log(props)
    return (
        <>
        <div style={{width: '100%', height:'350px',border: '2px solid black',padding: '2px'}}>
            <Row style={{ position: 'absolute'}}>
                <Col md={12}>
                    <img alt={"text"} style={{width: '100%',height:'342px',}} src={props.image} />
                </Col>
            </Row>
            <Row className="text-center" >
                <Col xs={12} style={style} >
                    {props.title}. 
                </Col>
            </Row>
            
            <Row className="text-right mr-1" style={style1} >
                <Col xs={12} className="mb-3" >
                    <Button variant="danger" className="btn-lg" onClick={props.retake}><i class="fa fa-repeat" aria-hidden="true"></i>&nbsp;Retake</Button>
                </Col>
            </Row>
        </div>
        </>
    );
}
 
export default SingleImageCompleteView;