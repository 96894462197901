import React from 'react';

const Dropdown = (props) =>{
    // console.log(props)
    let options = props.option && props.option.map((o,i)=> <option value={o.id}>{o.name}</option>)
    // console.log(options);
    return(
        <select className="form-control" value={props.value} required={props.required} onChange={props.onChange}>
            <option value={0} disabled selected>{props.title}</option>
            {options}
        </select>
    )
}

export default Dropdown;