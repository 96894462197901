import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

class ModalConfirm extends Component {
  render() {
    return (
      <div>
        <Modal size="md" isOpen={this.props.modal} >
          <ModalHeader ><div style={{fontWeight:'bold'}}>More Photos</div></ModalHeader>
          <ModalBody>
          <div className="card-body" style={{padding: '0px 1.25rem'}}>
            <form onSubmit={this.submitHandler}>
              <div className="row">
                <div className="col-sm-12" style={{color: '#142e4e', fontSize: 18, fontWeight: 'bold'}}>
                    <div className="form-group">
                        {this.props.modalTitle}
                    </div>
                </div>
                <div className="col-sm-5">
                </div>
                <div className="col-sm-3">
                    <div className="form-group">
                      <Button color="danger" className="btn-lg" onClick={this.props.toggle}><i class="fa fa-times"></i>&nbsp;No</Button>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="form-group">
                        <button type="button" onClick={this.props.click} className="btn btn-success waves-effect waves-light btn-lg"><i class="fa fa-check"></i>&nbsp;Yes</button>
                    </div>
                </div>
              </div>
            </form>
          </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default ModalConfirm;
