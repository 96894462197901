import React from 'react';
import {
    Row,
    Col,
    Button
}  from 'react-bootstrap';
import NavView from '../../component/NavBar';
import SingleInfoView from '../../component/SingleInfoView';
const InspectionInfo = (props) =>{

    
    return (
        <>
        <div >
            <NavView />
            <div style={{width:'100%'}}>
                <h4 className="text-center pt-3 pb-3 mb-3" style={{background:'white', borderRadius:10}}>How to take image </h4>
                <Row style={{fontSize: 19, margin:0}}>
                    <Col xs={12}>
                       Welcome, now you are ready to inspect the vehicle. Please note the instructions for clicking the photos.
                    </Col>

                    <Col xs={12} className="text-center mt-4 mb-4">
                        <Button onClick={props.handleChange} color="info" className="btn-lg" style={{width: '80%'}}>Go for Inspection&nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i></Button>
                    </Col>

                    <Col xs={12} style={{fontWeight:800}}>
                        Instructions
                    </Col>
                    <SingleInfoView
                        icon="fa fa-book"
                        title="Keep all the vehicle documents ready."
                        isLast={false}
                    />
                    <SingleInfoView
                        icon="fa fa-location-arrow"
                        title="Identify location of Chassis Number before starting the inspection."
                        isLast={false}
                    />
                    <SingleInfoView
                        icon="fa fa-camera"
                        title="Take correct photos to best fit the camera screen."
                        isLast={false}
                    />
                    <Col xs={12} className="text-center mt-5 mb-5">
                    
                    </Col>
                </Row>
            </div>
        </div>
        </>

    )
}

export default InspectionInfo;